import React from 'react'
import NotaryServicePoints from './NotaryServicePoints'

const NotaryServiceMain = () => {
  return (
    <div className="civilLawMainContainer">
      <NotaryServicePoints />
    </div>
  )
}

export default NotaryServiceMain
