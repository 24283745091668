import React from 'react'
import "./HomeFaq.css"
import HomeFaqMain from './HomeFaqMain'
const HomeFaq = () => {
  return (
    <div className='homeFaqContainer'>
      <HomeFaqMain/>
    </div>
  )
}

export default HomeFaq
