import React from "react";
import DivorcePoints from "./DivorcePoints";

const DivorceMain = () => {
  return (
    <div className="civilLawMainContainer">
      <DivorcePoints />
    </div>
  );
};

export default DivorceMain;
