import React from 'react'
import DraftingAgreementPoints from './DraftingAgreementPoints'

const DraftingAgreementMain = () => {
  return (
    <div className="civilLawMainContainer">
    <DraftingAgreementPoints />
  </div>
  )
}

export default DraftingAgreementMain
