import React from "react";
import PropertyDocPoints from "./PropertyDocPoints";

const PropertyDocMain = () => {
  return (
    <div className="civilLawMainContainer">
      <PropertyDocPoints />
    </div>
  );
};

export default PropertyDocMain;
