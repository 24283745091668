import React from "react";
import "./HomeSupport.css"
const HomeSupportHead = () => {
  return (
    <div className="homeSupportHeadContainer">
      <p>CLEAR ADVICE PROVIDERS</p>
      <h2>Expert <span>Legal</span> Support</h2>
    </div>
  );
};

export default HomeSupportHead;
