import React from "react";
import MarriageLawPoints from "./MarriageLawPoints";

const MarriageLawMain = () => {
  return (
    <div className="civilLawMainContainer">
      <MarriageLawPoints />
    </div>
  );
};

export default MarriageLawMain;
