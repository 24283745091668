import React from "react";
import LabourLawPoints from "./LabourLawPoints";

const LabourLawMain = () => {
  return (
    <div className="civilLawMainContainer">
      <LabourLawPoints />
    </div>
  );
};

export default LabourLawMain;
