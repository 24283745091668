import React from "react";
import HighCourtPoints from "./HighCourtPoints";

const HighCourtMain = () => {
  return (
    <div className="civilLawMainContainer">
      <HighCourtPoints />
    </div>
  );
};

export default HighCourtMain;
